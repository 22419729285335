.cards-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(177px, 1fr));
  gap: 60px;
  padding-top: 12px;
  row-gap: 32px;

  @media screen and (max-width: 1006px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
