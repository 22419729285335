.customize-use-case-title {
  margin-bottom: 16px;
  .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #1b2b4b;
  }

  .subtitle {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #404959;
  }
}
.audio-controls {
  display: flex;
  justify-content: space-between;

  .form-item-audio-control {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
}
.customize-video-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  // aspect-ratio: 9 / 16;
  border: 1px solid rgba(205, 213, 229, 0.6);

  .vid-wrapper {
    border-radius: 12px;
    height: 100%;
    width: 100%;
    aspect-ratio: 9 / 16;
    display: flex;
    flex-direction: column;
  }
  .__Blings__player__container {
    border-radius: 12px;
  }
}

.steps-wrapper .customize-use-case-content {
  max-height: 470px;
  overflow-y: auto;
}

.customize-template {
  .brand-customization-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 16px;
    // max-height: 400px;
    overflow-y: auto;
    padding-bottom: 16px;

    label {
      color: #404959;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
    }
    .ant-upload {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
    .ant-upload-list.ant-upload-list-text {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-upload-list-item-container {
        width: 100%;
      }
    }

    .custom-file-upload {
      cursor: pointer;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .logo-upload-wrapper {
      position: relative;
      height: 68px;
      width: 100%;
      overflow: hidden;
      &:hover {
        .container-file-upload {
          background-color: rgba(27, 43, 75, 0.85) !important;
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.75) !important;

          img {
            opacity: 0.3;
          }
        }
      }
    }

    .color-item-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .color-item-content {
        flex: 1 1 calc(50% - 16px);
        max-width: calc(50% - 16px);
        box-sizing: border-box;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #404959;
          margin-bottom: 12px;
        }
        .color-item {
          display: flex;
          gap: 15px;
          border: 1px solid #cdd5e5;
          border-radius: 6px;
          align-items: center;
          height: 32px;
          overflow: hidden;

          input[type="color"] {
            all: unset;
            width: 66px;
            height: 32px;
            border-radius: 6px 0 0 6px;
            cursor: pointer;
            border-right: 1px solid #cdd5e5;
          }

          label {
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #404959;
          }
        }
      }
    }
    .rounded-color-picker::-webkit-color-swatch {
      border-radius: 40px;
    }

    input[type="file"] {
      display: none;
    }

    .container-file-upload {
      display: flex;
      place-content: center;
      width: 100%;
      height: 100%;
      border: 1px solid #cdd5e5;
      border-radius: 6px;
      cursor: pointer;

      .loader {
        display: flex;
        width: 48px;
        height: 48px;
        border: 5px solid #fff;
        border-bottom-color: var(--blings_primary);
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        z-index: 999999;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      img {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
      }

      input {
        display: none;
      }
    }
  }

  .hover-file-upload {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    svg {
      width: 30px;
      height: 30px;
      color: #ffffff;
    }

    span {
      font-weight: 700;
      font-size: 12px;
      color: #ffffff;
      line-height: 22px;
    }

    .title {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      color: #fff;
    }
    .subtitle {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #fff;
    }
  }

  .logo-upload-wrapper:hover .hover-file-upload {
    display: flex;
  }

  .apply-template {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .apply-btn {
    width: 67px;
    height: 32px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
    background: #ffffff;
    color: #262626;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .reset-changes-btn {
    cursor: pointer;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 6px 0 0 6px;
    padding: 0;
    margin: 0;
    width: 100px;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    border: none;
    padding: 0;
  }
}
