@import "../consts";

.edit-content-main {
  height: 100%;
  overflow: auto;
  padding: 0rem calc(2rem + 16px) 20px 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .SubHeader {
    font-size: 20px;
    color: #1b2b4b;
    font-weight: 600;
  }
  .sub-SubHeader {
    font-size: 14px;
    color: #1b2b4b;
    font-weight: 500;
  }

  .Container {
    height: 100%;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    overflow: hidden;
    .Scenes {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      max-width: 280px;
      background-color: white;
      overflow: hidden;
      border: solid 1px #cdd5e5;
      border-radius: 12px;
    }
    .Edit {
      border: solid 1px #cdd5e5;
      border-radius: 12px;
      overflow: hidden;
      height: 100%;
      width: 100%;
      max-width: 580px;
      background-color: white;
      .SubHeader {
        font-size: 20px;
        color: var(--black-60, #45464e);
        font-weight: 700;
      }
      .EditViewHeader {
        .SubHeader {
          font-size: 20px;
          color: var(--black-60, #45464e);
          font-weight: 700;
          margin: 0.8rem 0;
          overflow: hidden;
          white-space: nowrap;
        }
        display: flex;
        align-items: center;
        gap: 0.3rem;
        justify-content: space-between;
        .SearchHeader {
          width: 50%;
          .Search {
            width: 100%;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .Demo {
      height: 100%;
      max-height: 100%;
      width: 100%;
      max-width: -webkit-fill-available;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .edit-video-DemoPage {
        max-width: -webkit-fill-available !important;
        display: contents !important;
      }
    }
  }
}
.edit-content-usecase-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow: hidden;
  min-width: 580px;
  max-width: 800px;
  width: 100%;
  background: #fff;
  padding: 16px 16px 0px 16px;
  gap: 0px;
  border-radius: 12px;
  border: 1px solid #cdd5e5;

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
  }
  .save-draft-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
    width: 352px;
  }
  .saving-draft-button {
    display: flex;
    align-items: center;
    width: 74px;
    gap: 1rem;
    font-weight: 700 !important;
    justify-content: flex-end;
    &:disabled {
      color: white !important;
      border: 1px solid #cdd5e5 !important;
      background-color: var(--blings_disabled_btn) !important;
    }
    :hover:not(:disabled) {
      color: #e3355f !important;
      border-color: #ffccd8 !important;
    }
  }
  .content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    .subtitle {
      width: 100%;
    }
  }
  .customize-use-case-content {
    width: 352px;
    overflow-y: auto;
  }
  .edit-content-usecase-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
    border-top: 1px solid #cdd5e5;
    padding: 23px 0px;

    .publish-changes-button {
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
    }
    .save-text {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #4d5566;
    }
  }
}
