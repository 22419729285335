.project-gallery-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 16px 0px #4a474731;

    .video-thumb-container {
      .video-thumb {
        transform: translateY(-12px);
      }
    }

    .project-details {
      transform: translateY(-25px);
    }
  }
  .background-effect {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    border: 2px solid white;
    border-radius: 12px;

    background: linear-gradient(0deg, #ffffff00, #ffffff00),
      linear-gradient(
        180deg,
        rgba(27, 43, 75, 0) 0%,
        rgba(27, 43, 75, 0.1) 100%
      );
  }

  .video-thumb-container {
    aspect-ratio: 9.11 / 16;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: 100%;
    border-radius: 12px;
    background: #ffffff;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    transition: transform 0.3s ease-in-out;

    .video-thumb {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: none;
      transition: transform 0.3s ease-in-out;
    }
  }

  .project-account-name {
    font-family: Inter;
    font-size: 10px;
    line-height: 15.17px;
    text-align: left;
    font-weight: 700;
    color: #f7f8fa;
    position: absolute;
    top: 7px;
    left: 6px;
    right: 8px;
    background: #262626;
    max-width: -webkit-fill-available;
    width: fit-content;
    height: 24px;
    padding: 4px 6px 4px 6px;
    border-radius: 6px;
    z-index: 999;

    &.text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .project-details {
    display: flex;
    flex-direction: column;
    gap: 3.5px;
    width: 100%;
    position: absolute;
    background: #ffffff;
    bottom: -25px;
    border-radius: 0 0 12px 12px;
    padding: 12px 12px 0 12px;
    transition: transform 0.3s ease-in-out;
    // box-shadow: 0px -6px 20px 11px rgb(157 150 150 / 19%);

    .project-gallery-card-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      word-break: break-word;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      height: 52px;
      color: #1b2b4b;
    }

    .project-gallery-card-date {
      font-size: 12px;
      font-weight: 400;
      color: #262626;
      height: 28px;
      font-family: "Inter";
    }

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
