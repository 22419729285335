.use-case-gallery {
  padding: 24px 0;
  min-height: 100%;
  max-width: 100%;
  margin: 0 150px;
  height: 100%;
  background: #f2f4f8;
}

.use-case-modal {
  width: 766px;

  overflow: hidden !important;

  .ant-modal-content {
    width: 100%;
    min-height: 656px !important;
    max-height: 690px !important;
    height: 100% !important;

    .ant-modal-close-x {
      color: #515458;
    }
    .ant-modal-close {
      &:hover {
        background: none !important;
      }
    }

    .ant-modal-body {
      height: 100%;
      overflow: hidden !important;
    }
  }
}
.use-case-gallery-header {
  width: 100%;
  display: flex;
  justify-content: start;

  .back-btn {
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter";
    color: #45464e;
    display: flex;
    align-items: center;
    background: none;
    padding-left: 0;

    &:hover {
      background: none !important;
    }
  }
}
