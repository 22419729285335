.steps-container {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 270px;
}

.step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}

.step-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  z-index: 1;
}

.step-label {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #cdd5e599;

  &.active {
    color: #0d0b26;
  }
}

.step-line {
  position: absolute;
  top: 20px;
  height: 1px;
  background-color: #cdd5e599;
  z-index: 0;
  transition: background-color 0.3s ease;
}

.step-line.active-line {
  background-color: #1b2b4b;
}

/* Adjust step-line width to span between icons */
.step-line {
  left: calc(50% + 20px);
  right: calc(-50% + 20px);
}

.step-buttons {
  margin-top: 20px;
}

.prev-btn,
.next-btn {
  background-color: #2196f3;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.prev-btn:hover,
.next-btn:hover {
  background-color: #1976d2;
}
