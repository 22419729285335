.dynamic-thumb-form {
  .titles {
    width: 65rem;
  }
  display: flex;
  flex-direction: column;
  .main-dynamic-thumbnail {
    display: flex;
    grid-column-gap: 5rem;
    column-gap: 5rem;
    flex-wrap: wrap;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    .form-container {
      width: 65rem;
    }
    .link-div {
      width: 37rem;
      .main-link-container {
        // height: 10rem;
        .copy-extra-information {
          justify-content: flex-end;
        }
      }
      .ant-tabs-nav {
        flex-flow: row-reverse;
        margin: 0 1em 0.5rem 0;
        &::before {
          border-bottom: 1px solid #f7f8fa;
        }
      }
    }
  }
  .atomic-block,
  .text-block {
    display: inline-block;
  }

  .header {
    font-size: 1rem;
    margin: 2rem 0 1rem 0;
    color: var(--blings_pink);
    font-weight: 500;
  }

  .sub-text {
    margin-bottom: 1rem;
  }

  .thumb-upload-new {
    & > div {
      width: 100%;
      display: flex;
    }
  }

  .container {
    display: flex;
    background-color: white;
    padding: 2rem;
    border: 1px solid #bebbbb;
    gap: 5rem;
    margin-bottom: 2rem;
  }

  .previewContainer {
    display: flex;
    flex-direction: column;
    width: 20rem;
    gap: 10px;
  }

  .preview-param-box {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 10px;
  }

  .paramBox {
    display: flex;
    flex-direction: column;
  }

  .previewText {
    margin-top: 18px;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 600;
  }

  .param1,
  .param2 {
    margin: 0px;
    margin-left: 5px;
    font-size: 12px;
  }

  .play-button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .playbutton,
  .uploadImgText,
  .textAboveEditor {
    margin: 0px;
    font-size: 20px;
    font-weight: 600;
  }

  .playbutton-settings {
    display: flex;
    gap: 20px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    width: 30rem;
  }
  .save-btn {
    display: flex;
    justify-content: flex-end;
  }
  .tooltip {
    max-width: 150px;
    font-size: 13px;
    margin-left: 8px;
    align-self: center;
  }

  .slateContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.25rem;
  }

  .aboveEditorContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .custom-upload-item {
    position: relative;
  }

  .custom-upload-item .upload-again-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    color: white;
    /* Text color */
    opacity: 0;
    transition: opacity 0.3s;
    /* Optional: adds a transition effect */
  }

  .custom-upload-item:hover .upload-again-button {
    opacity: 0.5;
  }

  //gpt start
  .image-container {
    width: 120px;
    height: 120px;
    position: relative;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
  }

  .image-container:hover img {
    filter: blur(2px);
  }

  .upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .image-container:hover .upload-overlay,
  .image-container.hovered .upload-overlay {
    display: flex;
  }

  //gpt end
  .previewImgContainer {
    width: 20rem;
    max-height: 20rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    .overlay-spinner {
      position: absolute;
      /* Makes the spinner overlay on the image */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      height: 100%;
    }
  }

  .blurred {
    filter: blur(5px);
  }

  .previewImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .uplaodImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .BlingsButton {
    margin-top: auto;
    align-self: flex-start;
  }

  .uploadImgText {
    margin-bottom: 1rem;
  }

  .textEditor {
    border: 1px solid #cccccc;
    padding: 0px 10px;
    border-radius: 3px;
    background-color: white;
    margin-top: 5px;
    word-wrap: break-word;
    white-space: normal;
    width: 90%;
    position: relative;

    span[data-slate-placeholder="true"] {
      display: inline;
      margin-top: 7px;
    }
  }

  .label-div {
    display: flex;
    align-items: center;
  }
  .label-div > label {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-right: 10px;
  }

  .paramButtons.ant-btn {
    padding: 0.2rem 0.4rem;
  }

  .textSelectorSpace {
    margin-top: 5px;
    display: flex;
  }
}

.final-list-item.ant-list-item {
  padding: 10px 10px 2px 8px;
  font-size: 12px;
  color: gray;
  gap: 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
