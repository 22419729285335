.welcome-container {
  padding: 24px 0;
  min-height: 100%;
  max-width: 100%;
  margin: 0 150px;
  height: 100%;
  background: #f2f4f8;

  .welcome-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    width: 100%;
    h1 {
      color: #1b2b4b;
      font-family: Inter;
      font-size: 24px;
      font-weight: 800;
      line-height: 31.43px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin: 0;
    }

    .subtitle {
      color: #1b2b4b;
      font-family: "Inter";
      font-weight: 400;
      font-size: clamp(12px, 14px, 14px);
      white-space: nowrap;
      margin: 0;
    }
  }
}
.project-gallery {
  position: relative;
  height: 100%;
  gap: 10px;
}
.project-gallery-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #cdd5e5;
  position: sticky;
  background: var(--blings_pages_background);
  top: -5px;
  z-index: 9999;

  &.usecase {
    border-top: none;
    padding-top: 0;
  }

  .Right-Side {
    .ant-select-selector {
      height: 36px !important;
    }
    .Search {
      .ant-input {
        border: unset !important;
      }
      max-width: 300px !important;
    }
  }
  .SubHeader {
    font-size: 16px;
    font-weight: 700;
    color: #1b2b4b;
    font-family: "Inter";

    &.usecase {
      font-size: 16px;
    }
  }
}
.phone-mockup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  min-height: 100%;
}

@media screen and (min-width: 1540px) {
  .phone-mockup {
    right: 10%;
  }
}
.journey-card {
  height: 333px !important;
  border-radius: 12px;
  overflow: hidden;

  .ant-card-body {
    height: 100%;
    padding: 24px 10px 29px 47px;
    position: relative;
    background: #0f0f0f;
  }

  .journey-content {
    display: flex;
    gap: 4rem;
    height: 100%;

    .left-content {
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .journey-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 426px;
        width: 100%;
      }
      .checklist {
        row-gap: 0;
      }
      h2 {
        font-size: 36px;
        transform-origin: left center;
        margin-bottom: 24px;
        line-height: 37px;
        color: #fff;
        font-family: Poppins;
        font-weight: 300;
      }
      .checklist-item {
        margin-bottom: 7px;
        color: #fff;
        font-family: "Poppins";
        font-weight: 300;
        font-size: 14px;
      }
    }

    .right-content {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 47px;
      overflow: hidden;
    }
  }
}

.checklist-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1rem;

  .check-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1890ff;
    color: white;
    font-size: 0.8rem;
  }
}

.create-usecase-button {
  margin-top: 20px;
  width: 215px;
  height: 50px !important;
  padding: 0px 28px 0px 28px !important;
  gap: 0px;
  border-radius: 6px 0px 0px 0px;
  color: #000 !important;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  background: linear-gradient(
    277.64deg,
    #e0e0e0 -8.72%,
    #ffffff 35.16%
  ) !important;

  &:hover {
    background-color: #333;
    border-color: #333;
  }
}

.get-started-section {
  margin-top: 32px;
  p {
    font-family: "Inter";
    font-size: 16px;
    color: #404959;
    line-height: 31.43px;
    font-weight: 400;
  }
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 63px;
  gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }

  .option-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid #cdd5e5;
    background: #ffffff;
    color: #404959;
    height: 63px;
    justify-content: center;
    border-radius: 10px;

    &:hover {
      &.browse {
        border: 1px solid #ff366626;
        box-shadow: 0px 0 12px 0px #ff366626;
      }
      &.ai {
        border: 1px solid #9747ff26;
        box-shadow: 0px 0 12px 0px #9747ff26;
      }
      &.after-effects {
        border: 1px solid #1f1fff26;
        box-shadow: 0px 0 12px 0px #1f1fff26;
      }
    }

    .option-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px 15px;
      gap: 16px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #f2f4f8;
      }
      .option-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .title {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 700;
        line-height: 19.54px;
      }
      .subtitle {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 14.63px;
      }
    }

    .anticon {
      font-size: 1.5rem;
      color: #1890ff;
    }

    span {
      font-family: "Inter";
      font-weight: 700;
      font-size: clamp(10px, 14px, 14px);

      white-space: nowrap;
    }
  }
}
