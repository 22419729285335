.carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .content-wrapper {
    border: 1px solid rgba(205, 213, 229, 1);
    width: 100%;
    height: 100%;
    border-radius: 12px;
    aspect-ratio: 9 / 16;
    overflow: hidden;
  }
}

.carousel-container {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  aspect-ratio: 9 / 16;
  overflow: hidden;
  position: relative;
}

.carousel-track {
  width: 100%;
  height: 100%;

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.25);
  }

  img,
  .player-wrapper {
    width: 100%;
    height: 100%;
    object-fit: fill;
    flex-shrink: 0;
    border-radius: 12px;
  }

  .loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid var(--blings_primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-top: 0;
  }

  .vid-wrapper {
    border-radius: 12px;
    height: 100%;
    aspect-ratio: 9 / 16;
    display: flex;
    flex-direction: column;
  }
  &.next {
    animation: slideLeft 0.3s ease-in-out;
  }

  &.prev {
    animation: slideRight 0.3s ease-in-out;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.carousel-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20px;
  margin-top: 16px;
}

.arrow {
  background: none;
  border: none;
  color: #7f8899;
  display: flex;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    color: #1e1e1e;
  }
}

.arrow:focus {
  outline: none;
}

.dots {
  display: flex;
  gap: 0.5rem;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #cdd5e5;
}

.dot.active {
  background-color: #1e1e1e;
}
