.use-case-main-wrapper {
  display: flex;
  height: 100%;
  border-radius: 0;
  column-gap: 32px;
  overflow: hidden;

  .next-btn-wrapper {
    display: flex;
    width: 100%;
    gap: 10px;
  }
  .revert-btn {
    height: 42px;
    width: 78px;
    color: #2e2e2e !important;
    background: #ffffff !important;

    padding: 0px 16px;
    border-radius: 6px !important;
    border: 1px solid #2e2e2e;

    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;

    &:disabled {
      border: 1px solid #cdd5e5;
      color: #cdcdcd !important;
      background: none !important;
    }
    &:hover:not(:disabled) {
      background: none !important;
      color: #7f7f7f !important;
      border: 1px solid #7f7f7f !important;
    }
  }

  .next-step-btn {
    height: 42px;
    border-radius: 6px !important;
    color: #ffffff;
    background: #2e2e2e;
    margin-right: 16px;
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;

    &:disabled {
      background: #e0e0e0 !important;
      color: #ffffff !important;
      border: 1px solid #e0e0e0 !important;
    }
    &:hover:not(:disabled) {
      background: #4c4c4c !important;
      color: #ffffff !important;
      border: 1px solid #4c4c4c !important;
    }
  }
}

.step-done {
  .preparing {
    margin-top: 134px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #1b2b4b;
    }
    .message {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #404959;
    }
  }
}

.steps-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  height: 100%;
}

.select-account-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .subtitle {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #404959;
  }
  .select-account {
    color: #1b2b4b;
  }
  .title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #1b2b4b;
  }
}
.ant-modal-content {
  background: #ffffff !important;
  padding: 16px !important;
  .ant-modal-close {
    margin: 0;
  }
}
.use-case-left-side {
  display: flex;
  justify-content: center;
  width: 351px;
  min-width: 351px;
  height: 100%;
  position: relative;
  border-radius: 12px;

  .ant-wrapper-carousel {
    display: flex;
    flex-direction: column;
    width: 351px;
    min-width: 351px;
    aspect-ratio: 9 / 16;
    overflow: hidden;
  }
}

.use-case-right-side {
  display: flex;
  flex-direction: column;
  width: 351px;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  background: #ffffff;

  .rename-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 134px;
    padding-right: 16px;
    gap: 16px;

    .title {
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #1b2b4b;
    }

    .rename-input-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 8px;

      .rename-input {
        border: 1px solid #cdd5e5;
      }
      .subtitle {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #404959;
      }
    }
  }
  .use-case-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .use-case-title {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 134px;

    h1 {
      color: #1b2b4b;
      margin: 0;
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    p {
      margin: 0;
    }
  }
  .tag-list {
    gap: 8px;
    display: flex;
    align-items: center;
    height: 24px;

    width: 100%;
    overflow: auto hidden;

    &::-webkit-scrollbar-thumb {
      background: none !important;
    }
    &::-webkit-scrollbar {
      height: 6px !important;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #babac0 !important;
      }
    }
  }
  .usecase-tag {
    height: 24px;
    padding: 0px 8px;
    border-radius: 20px;
    border: none;
    color: #4d5566;
    background-color: #f2f4f8;
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    line-height: 24px;
    white-space: nowrap;
  }
  .about-template {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    h3 {
      color: #404959;
      font-family: "Inter";
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      text-align: left;
    }
  }
  .use-template-btn {
    width: 138px;
    height: 32px;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
    background: #262626;

    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
