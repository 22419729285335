.use-case-wrapper {
  display: flex;
  height: 100%;
  border-radius: 20px;
  background: #ffffff !important;
}

.left-side {
  min-width: 426px;
  min-height: 641px;
  img {
    width: 426px;
  }
}

.right-side {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-left: 16px;
  place-content: space-between;

  .header {
    display: flex;
    margin-top: 12px;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    padding-top: 12px;
    padding-bottom: 12px;

    h1 {
      color: #404959;
      margin: 0;
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
    }
  }

  .shine-detail {
    padding: 3.5px 8px;
    background: linear-gradient(112.6deg, #ff154d -0.62%, #ff7aaa 89.95%);
    font-family: Inter;
    font-size: 10px;
    line-height: 17px;
    font-weight: 700;
    border-radius: 5px;
    color: #ffffff;
  }

  .step-container {
    display: flex;
    gap: 16px;
    margin-bottom: 14px;
    padding-right: 18px;

    .countage {
      display: flex;
      flex-direction: column;
      align-items: center; /* Centraliza o conteúdo horizontalmente */

      span {
        border: 1px solid #404959;
        width: 24px;
        height: 24px;
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1px;

        font-family: Inter;
        font-size: 12px;
        line-height: 20px;
      }

      .line-detail {
        width: 1px;
        height: 95px;
        background: linear-gradient(271.82deg, #ff7aaa -8.22%, #ff154d 106.32%);
        margin-top: 14px;
      }
    }

    h2 {
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #404959;
    }

    p {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      color: #404959;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-right: 18px;
    padding-bottom: 6px;

    p {
      font-family: Inter;
      font-size: 12px;
      line-height: 17px;
      font-weight: 400;
      border-radius: 5px;
      color: #404959;
      margin: 0;
    }

    .use-template-btn {
      width: 100%;
      height: 42px;
      padding: 16px;
      margin-top: 6px;
      background-color: #2e2e2e;
      color: #ffffff;
      font-weight: 700;
      font-size: 14px;

      &:hover {
        background-color: #4c4c4c !important;
        color: #ffffff;
        border: none;
      }
    }
  }

  .footer p:first-of-type {
    margin-bottom: 6px;
  }
}
